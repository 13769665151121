import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Logo from "../@narative/gatsby-theme-novela/components/Logo/Logo";

const baseUri =
    "https://s3.ca-central-1.amazonaws.com/static-nuvahs-edge-node-ca-central.repo.host/data-cdn";

class RenderHTMLView extends React.Component {
    public render() {
        return (
            <>
                <Link to={"/"}>
                    <Logo fill={"white"} />
                </Link>
                <StyledFrame src={`${baseUri}/privacy-policy.html`} />
            </>
        );
    }
}

export default RenderHTMLView;

const StyledFrame = styled.iframe`
    width: 100vw;
    height: 100vh;
`;
